import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
// import Star from "../Assets/AGROVEST.png";

const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className="flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4  text-green-400">
      {/* <img src={Star} alt="/img" className="w-[150px] h-[150px]" /> */}
      <h1 className="w-full text-4xl font-bold text-[#024415D8] mx-8">
        <Link to="/">AGROVEST</Link>
      </h1>
      <ul className="lg:flex w-full hidden">
        <li className="relative group">
          {/* Navbar dropdown */}
          <p className="p-4 hover:text-green-900 hover:text-white transition-colors duration-300 rounded-full text-[#024415D8]">
            <Link to="/about">About</Link>
          </p>
        </li>
      
        <li className="p-4 hover:text-green-900 hover:text-white transition-colors duration-300 rounded-full text-[#024415D8]">
          <Link to="/register">Register</Link>
        </li>
        <li className="p-4 hover:text-green-900 hover:text-white transition-colors duration-300 rounded-full text-[#024415D8]">
          <Link to="/login">Login</Link>
        </li>
      </ul>

      <div onClick={handleNav} className="block lg:hidden">
        {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>
      <ul
        className={
          nav
            ? "fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500"
            : "fixed left-[-100%]"
        }
      >
        <h1 className="w-full text-3xl font-bold text-[#0492c2] m-4">
          AGROVEST
        </h1>
      
        <li className="p-4 border-b border-gray-600">
          <Link to="/register">Register</Link>
        </li>
        <li className="p-4">
          <Link to="/login">Login</Link>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;

import React from "react";
import communityData from "./CommunityInput";

function Community() {
  return (
    <div className="w-full py[6rem] px-4">
      <h1 className="font-bold text-green-900 text-3xl sm:text-4xl md:text-5xl my-8 text-center">
        Join our growing community of international investors
      </h1>
      <div className="max-w-[1240px] mx-auto grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-4">
        {communityData.map((communityData, id) => (
          <div
            key={id}
            className="shadow-xl flex flex-col p-2 my-4 rounded-lg text-green-900"
          >
            <img className="rounded-lg" src={communityData.image} alt="img" />
            <h1 className="font-bold text-white">{communityData.name}</h1>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Community;

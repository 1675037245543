import React from "react";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import faqinfo from "./FAQInfo";
import faqinfo2 from "./FAQinfo2";

function FAQ() {
  return (
    <div>
      <Navbar />
      <div className="w-full pb-10 text-white">
        <div className="px-4 mt-[66px] max-w-[1330px] mx-auto pb-10 bg-[#024415D8] rounded-[50px]">
          <div className="flex justify-center">
            <h1 className="mt-8 mx-auto text-center text-4xl md:text-7xl font-bold">
              Frequently Asked Questions
            </h1>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center py-[4rem]">
        <h1 className="text-green-900 mx-auto text-center font-bold md:text-6xl sm:text-4xl text-3xl">
          Frequently Asked Questions
        </h1>

        <h3 className="text-green-900 text-2xl mx-auto py-[4rem] font-bold">
          Your Right To Know
        </h3>
      </div>

      <div className="w-full px-4">
        <div className="max-w-[1240px] mx-auto grid sm:grid-cols-2 md:grid-cols-3 gap-8">
          {faqinfo.map((faqinfo, id) => (
            <div
              key={id}
              className="shadow-xl flex flex-col p-2 my-4 rouded-lg text-green-100"
            >
              <h1 className="font-bold text-green-900 text-2xl">
                {faqinfo.question}
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                {faqinfo.answer}
              </p>
            </div>
          ))}
        </div>
      </div>

      <div className="flex justify-center">
        <h3 className="mx-auto text-2xl font-bold text-green-900 py-[4rem] underline">
          Still Curious ?
        </h3>
      </div>

      <div className="w-full px-4 py-[4rem]">
        <div className="max-w-[1240px] mx-auto grid sm:grid-cols-2 md:grid-cols-3 gap-8">
          {faqinfo2.map((faqinfo2, id) => (
            <div
              key={id}
              className="shadow-xl flex flex-col p-2 my-4 rouded-lg text-green-100"
            >
              <h1 className="font-bold text-green-900 text-2xl">
                {faqinfo2.question}
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                {faqinfo2.answer}
              </p>
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default FAQ;

import React, { useEffect } from "react";
import Benefits from "./Benefits";
import Community from "./Community";
import FeatureCards from "./FeatureCards";
import Features from "./Features";
import Footer from "./Footer";
import Hero from "./Hero";
import InvestmentFactors from "./InvestmentFactors";
import Investors from "./Investors";
import MarketCards from "./MarketCards";
import Navbar from "./Navbar";
import Partnership from "./Partnership";
import Profits from "./Profits";
import Reviews from "./Reviews";
import TradingSteps from "./TradingSteps";
import Contract from "./Contract";
import { useToast } from '@chakra-ui/react'

function Home() {
  const toast = useToast()
  useEffect(()=>{
    toast({
      title: 'Important Update: Customer Service on WhatsApp',
      description: "We are excited to inform you that we have transitioned our customer service to WhatsApp. We are no longer using Tildo. Thank you for your continued support.",
      status: 'info',
      duration: 9000,
      isClosable: true,
      position:"top-right"
    })
  },[])
  return (
    <div>
      <Navbar />
      <Hero />
      <Investors />
      <Profits />
      <InvestmentFactors />
      <TradingSteps />
      <Benefits />
     
      <Features />
      <FeatureCards />
      <Contract />
      <Partnership />
      <Community />
      <Reviews />
      <Footer />
    </div>
  );
}

export default Home;

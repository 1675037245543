import React from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Hero() {
  return (
    <div className="w-full text-white pb-10">
      <div className="px-4 mt-[66px] max-w-[1330px] mx-auto pb-10 bg-[#024415D8] rounded-[50px]">
        <div className=" flex flex-col md:justify-center">
          <h1 className="mt-8 mx-auto text-center text-4xl md:text-7xl font-bold">
            Invest For Maximum Returns
          </h1>

          <p className="mt-3 mx-auto text-center text-1xl md:text-2xl">
            All it takes is a few moments and your account is ready for full
            trading in real assets.
          </p>
          <button className="text-green-200 bg-white  mt-8 w-[200px] rounded-full mx-auto font-medium py-3 text-green-900">
            <Link to="/register">Invest</Link>
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Hero;

import React from "react";
import Navbar from "../Components/Navbar";
import Finance from "../Assets/Finance.jpg";
import MarketCards from "../Components/MarketCards";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";

function Market() {
  return (
    <div>
      <Navbar />
      <div className="w-full pb-10 text-white">
        <div className="px-4 mt-[66px] max-w-[1330px] mx-auto pb-10 bg-[#024415D8] rounded-[50px]">
          <div className="flex justify-center">
            <h1 className="mt-8 mx-auto text-center text-4xl md:text-7xl font-bold">
              AGROVEST Market Overview
            </h1>
          </div>
        </div>
      </div>

      <div className="py-16 w-full px-4">
        <div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
          <div className="flex flex-col justify-center">
            <h1 className="text-green-900 font-bold md:text-5xl sm:text-4xl text-3xl pb-8">
              Financial Instruments Overview
            </h1>
            <p className="text-[rgb(111,111,111)] p-3 font-bold">
              There are several exchange markets where liquid financial
              instruments are traded. We offer to get acquainted with each of
              them in more detail in order to study the features and specifics
              of CFD trading in various assets.
            </p>
            <h4 className="text-green-900 underline font-bold p-3">
              <Link to="/register">Open Account</Link>
            </h4>
          </div>
          <img
            className="w-[600px] mx-auto my-4 rounded-[20px]"
            src={Finance}
            alt="img"
          />
        </div>
      </div>
      <MarketCards />

      <div className="w-full bg-gray-200 px-4 py-[6rem]">
        <div className="max-w-[1240px] mx-auto">
          <h1 className="text-center text-green-900 font-bold md:text-6xl sm:text-4xl text-4xl py-7">
            Numbers That Count
          </h1>
          <div className="grid sm:grid-cols-2 md:grid-cols-4 text-center">
            <div>
              <h1 className="text-green-900 font-bold md:text-6xl sm:text-4xl text-3xl py-10">
                104K+
              </h1>
              <p className="text-green-900 text-2xl">Investors</p>
            </div>
            <div>
              <h1 className="text-green-900 font-bold md:text-6xl sm:text-4xl text-3xl py-10">
                62+
              </h1>
              <p className="text-green-900 text-2xl">Experts on board</p>
            </div>
            <div>
              <h1 className="text-green-900 font-bold md:text-6xl sm:text-4xl text-3xl py-10">
                78+
              </h1>
              <p className="text-green-900 text-2xl">Countries</p>
            </div>
            <div>
              <h1 className="text-green-900 font-bold md:text-6xl sm:text-4xl text-3xl py-10">
                274+
              </h1>
              <p className="text-green-900 text-2xl">Positive reviews</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Market;

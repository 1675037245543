import React from "react";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import Mining from "../Assets/Mining.jpg";
import Office from "../Assets/Office.jpg";
import { Link } from "react-router-dom";
import Features from "../Components/Features";

function Cryptomining() {
  return (
    <div>
      <Navbar />
      <div className="w-full text-white pb-10">
        <div className="px-4 mt-[66px] max-w-[1330px] mx-auto pb-10 bg-[#024415D8] rounded-[50px]">
          <div className="flex justify-center">
            <h1 className="mt-8 mx-auto text-center text-4xl md:text-7xl font-bold">
              AGROVEST Crypto Mining
            </h1>
          </div>
        </div>

        <div className="py-16 w-full px-4 h-[90vh] lg:h-[70vh] md:h-[30vh]">
          <div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
            <div className="flex flex-col justify-center">
              <h1 className="text-green-900 font-bold md:text-6xl sm:text-4xl text-3xl">
                Profit on Crypto Mining
              </h1>
              <p className="p-3 text-[rgb(111,111,111)] font-bold">
                Bitcoin mining is the process of creating new bitcoins by
                solving extremely complicated math problems that verify
                transactions in the currency. When a bitcoin is successfully
                mined, the miner receives a predetermined amount of bitcoin.
              </p>
              <h4 className="underline font-bold text-green-900 p-3">
                <Link to="/register" className="link">
                  Open Account
                </Link>
              </h4>
            </div>
            <img
              className="w-[600px] mx-auto my-4 rounded-[20px]"
              src={Mining}
              alt="img"
            />
          </div>
        </div>

        <div className="w-full px-4 py-[4rem] lg:h-[50vh] md:h-[30vh] h-[110vh]">
          <div className="max-w-[1240px] mx-auto grid sm:grid-cols-2 md:grid-cols-3 gap-8">
            <div className="shadow-xl flex flex-col p-2 my-4 rouded-lg text-green-100">
              <h1 className="font-bold text-green-900 text-2xl">
                Lucrative Mining
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                One compelling reason why you should consider crypto mining is
                the incentives of doing so. The Bitcoin blockchain is
                continuously rising in popularity, raising its value. Once
                you're successful in mining a particular block, you can stand to
                gain great rewards.
              </p>
            </div>

            <div className="shadow-xl flex flex-col p-2 my-4 rouded-lg text-green-100">
              <h1 className="font-bold text-green-900 text-2xl">
                Increased Prices
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                Like other cryptocurrencies in the market, Bitcoin has steadily
                increased in the past couple of years. While the price is very
                volatile, the general trend is that things are looking up for
                those who invest in Bitcoin.
              </p>
            </div>

            <div className="shadow-xl flex flex-col p-2 my-4 rouded-lg text-green-100">
              <h1 className="font-bold text-green-900 text-2xl">
                Impossible to Counterfeit
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                Unlike fiat currencies or digital currencies, Bitcoin
                transactions are recorded on a public ledger, meaning anyone can
                verify transactions. The Bitcoin network is highly
                sophisticated, assuring you that your reward for your mining
                efforts is legitimate and can't be faked.
              </p>
            </div>
          </div>
        </div>
        <Features />

        <div className="py-20 px-4 h-[120vh] md:h-[40vh] lg:h-[70vh]">
          <div className="max-w-[1240px] mx-auto grid md:grid-cols-2 gap-8">
            <img
              className="w-[500px] mx-auto my-4 rounded-[20px]"
              src={Office}
              alt="img"
            />
            <div className="flex flex-col justify-center">
              <h1 className="text-green-900 font-bold md:text-6xl md:mx-3 sm:text-4xl text-3xl">
                More About Crypto Mining
              </h1>

              <p className="p-3 text-[rgb(111,111,111)] font-bold">
                While the cryptocurrency prices may be volatile, the bitcoins
                stored in your wallet are still worth something. Although
                improbable, if the bitcoin price drops by 95%, your bitcoins
                won't amount to zero. By the looks of the market today, that
                scenario is highly unlikely, so the bitcoins you earn are worth
                a lot of money. In addition, there's no concept of debt in
                cryptocurrencies. No matter what happens, the Bitcoin you gain
                from mining is still worth something. You won't incur debt no
                matter how low the price of Bitcoin becomes.
              </p>

              <h4 className="underline font-bold text-green-900 p-3">
                <Link to="/register" className="link">
                  Open Account
                </Link>
              </h4>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Cryptomining;

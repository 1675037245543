import React from "react";
import { Link } from "react-router-dom";
import Investor from "../Assets/Invest.png";

function Investors() {
  return (
    <div className="w-full bg-white py-16 px-4 md:h-[40vh] lg:h-[50vh]">
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
        <div className="flex flex-col justify-center">
          <h1 className="text-green-900 font-bold md:text-6xl sm:text-4xl text-3xl">
            Over 1.4 Million Investors Worldwide
          </h1>
          <p className="p-3 text-[rgb(111,111,111)] font-bold">
            There is a high level of confidence among traders from all the world
            as the number of active accounts on our platform is always on the
            increase.
          </p>
          <p className="p-3 text-[rgb(111,111,111)] font-bold">
            The software for online trading is characterized by functionality,
            speed and modern web design.
          </p>
          <h2 className="underline font-bold text-green-900 p-3">
            <Link to="/about">Read More</Link>
          </h2>
        </div>
        <img className="w-[500px] mx-auto my-4" src={Investor} alt="img" />
      </div>
    </div>
  );
}

export default Investors;

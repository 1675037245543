const faqinfo2 = [
  {
    id: 1,
    question: "Can I Deposit Directly From My Account Balance ?",
    answer:
      "Yes. On the platform, there is possibility to invest funds from the balance in your personal account, which allows you to avoid the cost of a payment system Commission.",
  },

  {
    id: 2,
    question:
      "Is There Any Commission For Replenishing Personal Balance On The Platform ?",
    answer:
      "No we don't charge any interest for replenishing the balance. However, when making any payment, it is necessary to take into account the commision of the payment system itself.",
  },

  {
    id: 3,
    question: "How Can I Check The Balance In My Account ?",
    answer:
      "Log into your account: the balance is on the main page of your account.",
  },

  {
    id: 4,
    question: "How Do You Calculate The Interest On The Deposit ?",
    answer:
      "Calculation of your profit is carried out depending on the chosen plan, the amount of the deposit as well as its validity term.",
  },

  {
    id: 5,
    question: "Money Has Not Been Credited To My E-Wallet. What Can I Do?",
    answer:
      "Ensure that you sent a withdrawal request(view the history of transactions in your account). Check if 10 working hours have passed since the submission of your withdrawal request. if the funds were not credited within 10 working hours, contact our support team. Our experts will solve the problem in an extremely short period of time.",
  },

  {
    id: 6,
    question: "How Long Does It To Transfer Funds ?",
    answer:
      "As a rule, the transfer of funds to the user's electronic currency account is carried out within 10 working hours.",
  },

  {
    id: 7,
    question:
      "What Are The Advantages Of The Referral Program ? How Can I Participate ?",
    answer:
      "The referral program from AGROVEST is your oppurtunity to get passive income on our platform without investing personal funds and any time costs. Participating in the referral program is extremely simple. Tell your friends and acquaintances about the benefits of working with AGROVEST. Copy the unique referral link in your account and help potential partners sign up using it. Get bonuses from all the deposits of your invited partners for life.",
  },

  {
    id: 8,
    question: "Do You Have The Referral Program ?",
    answer:
      "Yes. Use all opportunities to earn money with AGROVEST simultaneously.",
  },

  {
    id: 9,
    question: "How Will I Get My Referral Commission ?",
    answer:
      "The commision will be credited to your balance automatically, immediately after the deposit is created by the referral.",
  },
];

export default faqinfo2;

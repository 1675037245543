import React from "react";
import Navbar from "../Components/Navbar";
import CryptoCurrency from "../Assets/Crypto.jpg";
import { Link } from "react-router-dom";
import Features from "../Components/Features";
import Office from "../Assets/Office.jpg";
import Footer from "../Components/Footer";

function Cryptocurrency() {
  return (
    <div>
      <Navbar />
      <div className="w-full text-white pb-10">
        <div className="px-4 mt-[66px] max-w-[1330px] mx-auto pb-10 bg-[#024415D8] rounded-[50px]">
          <div className="flex justify-center">
            <h1 className="mt-8 mx-auto text-center text-4xl md:text-7xl font-bold">
              CryptoCurrency Value
            </h1>
          </div>
        </div>

        <div className="py-16 w-full px-4 h-[90vh] md:h-[30vh] lg:h-[80vh] xl:h-[70vh]">
          <div className="max-w-[1240px] mx-auto grid md:grid-cols-2 gap-8">
            <div className="flex flex-col justify-center">
              <h1 className="text-green-900 font-bold md:text-6xl sm:text-4xl text-3xl">
                Trade CFD's on CryptoCurrency
              </h1>
              <p className="p-3 text-[rgb(111,111,111)] font-bold">
                Decentralized digital currencies are available for online
                trading with credit leverage. Choose the most popular
                instruments in this category, including Bitcoin and numerous
                altcoins.
              </p>
              <h4 className="underline font-bold text-green-900 p-3">
                <Link to="/register" className="link">
                  Open Account
                </Link>
              </h4>
            </div>
            <img
              className="w-[600px] mx-auto my-4 rounded-[20px]"
              src={CryptoCurrency}
              alt="img"
            />
          </div>
        </div>

        <div className="w-full px-4 py-[4rem] xl:h-[50vh] lg:h-[60vh] md:h-[35vh] h-[120vh]">
          <div className="max-w-[1240px] mx-auto grid sm:grid-cols-2 md:grid-cols-3 gap-8">
            <div className="shadow-xl flex flex-col p-2 my-4 rouded-lg text-green-100">
              <h1 className="font-bold text-green-900 text-2xl">
                CFD Trading Without A Cryptocurrency Wallet
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                It is very convenient to trade Bitcoin and altcoins for the
                price differences since it is not needed to register a special
                wallet.
              </p>
            </div>

            <div className="shadow-xl flex flex-col p-2 my-4 rouded-lg text-green-100">
              <h1 className="font-bold text-green-900 text-2xl">
                More Than 10 Types Of Cryptocurrencies
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                Choose the most liquid and promising cryptocurrencies for online
                trading.
              </p>
            </div>

            <div className="shadow-xl flex flex-col p-2 my-4 rouded-lg text-green-100">
              <h1 className="font-bold text-green-900 text-2xl">
                Convenient Management Of Deals
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                Evaluate the convenient and informative web design of the
                trading platform for trading cryptocurrency and other
                instruments.
              </p>
            </div>
          </div>
        </div>
        <Features />

        <div className="py-20 px-4 h-[100vh] md:h-[35vh] lg:h-[70vh]">
          <div className="max-w-[1240px] mx-auto grid md:grid-cols-2 gap-8">
            <img
              className="w-[500px] mx-auto my-4 rounded-[20px]"
              src={Office}
              alt="img"
            />
            <div className="flex flex-col justify-center">
              <h1 className="text-green-900 font-bold md:text-6xl md:mx-3 sm:text-4xl text-3xl">
                More About Crypto Trading
              </h1>

              <p className="p-3 text-[rgb(111,111,111)] font-bold">
                The financial market of goods is represented by energy
                resources(oil and gas) and agricultural products. The prices of
                commodities on the world commodity exchange depends on the ratio
                of supply and demand, as well as on external fundamental
                factors.
              </p>

              <h4 className="underline font-bold text-green-900 p-3">
                <Link to="/register" className="link">
                  Open Account
                </Link>
              </h4>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Cryptocurrency;

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Components/Home";
import About from "./Pages/About";
import License from "./Pages/License";
import Login from "./Pages/Login";
import Market from "./Pages/Market";
import Register from "./Pages/Register";
import TradingConditions from "./Pages/TradingConditions";
import FAQ from "./Pages/FAQ";
import Cryptocurrency from "./Pages/Cryptocurrency";
import Commodities from "./Pages/Commodities";
import Cryptomining from "./Pages/Cryptomining";
import Metals from "./Pages/Metals";
import Indices from "./Pages/Indices";
import Stocks from "./Pages/Stocks";
import ScrollToTop from "./Components/ScrollToTop";
import Contact from "./Pages/Contact";
import Dashboard from "./Dashboard/Dashboard";
import Features from "./Components/Features";
import InvestmentFactors from "./Components/InvestmentFactors";
import Profile from "./Pages/Profile";
import { ChakraProvider } from "@chakra-ui/react";
import { FloatingWhatsApp } from 'react-floating-whatsapp'

function App() {
  return (
    <div>
      <ChakraProvider>
        <BrowserRouter>
        <FloatingWhatsApp 
        chatMessage="Hi there 👋 If you need any assistance, I'm always here."
        statusMessage="We reply immediately"
        placeholder="Enter your message..."
        accountName="AGROVEST"
        notificationSound={true}
        messageDelay={1}
        phoneNumber="+2349048698144"
        allowClickAway={true}
        allowEsc={true}
        style={{}}

        chatboxHeight={500}
        /* Props for the chat widget */ />
          <ScrollToTop />
          <Routes>
            <Route path="/">
              <Route path="/" index element={<Home />} />
              <Route path="about" element={<About />} />
              <Route path="tradingconditions" element={<TradingConditions />} />
              <Route path="market" element={<Market />} />
              <Route path="register" element={<Register />} />
              <Route path="login" element={<Login />} />
              <Route path="license" element={<License />} />
              <Route path="faq" element={<FAQ />} />
              <Route path="cryptocurrencies" element={<Cryptocurrency />} />
              <Route path="commodities" element={<Commodities />} />
              <Route path="cryptomining" element={<Cryptomining />} />
              <Route path="metals" element={<Metals />} />
              <Route path="indices" element={<Indices />} />
              <Route path="stocks" element={<Stocks />} />
              <Route path="contact" element={<Contact />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="referral" element={<Features />} />
              <Route path="factors" element={<InvestmentFactors />} />
              <Route path="profile" element={<Profile />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ChakraProvider>
    </div>
  );
}

export default App;

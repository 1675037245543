import React, { useState } from "react";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { Link } from "react-router-dom";

function FeatureCards() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="w-full py-[6rem] px-4 ">
      <div
        className="max-w-[1240px] mx-auto grid md:grid-cols-2 gap-8"
        id="features"
      >
        <div className="shadow-xl flex flex-col p-2 my-4 rounded-lg text-green-100">
          <h1 className="font-bold text-3xl sm:text-4xl md:text-5xl mx-8 text-green-900">
            Short-term Plan
          </h1>

          <h1 className="text-3xl text-black sm:text-4xl md:text-5xl font-bold mx-8 my-3">
            <span>₦</span>5000
          </h1>

          <p className="text-[rgb(111,111,111)] mx-8 my-5">Minimum Deposit</p>

          <h1 className="text-green-900 font-bold text-2xl sm:text-3xl md:text-4xl mx-8 my-3 underline">
            Features
          </h1>

          <p className="p-4 text-[rgb(111,111,111)] font-bold">
            Short-term Investment starts from Five thousand Naira (₦5000) and
            Eigth Thousand Five Naira (₦8000) invested amount in return in (72) hours.
          </p>
          <div class="flex justify-center">
  <table class="min-w-full divide-y divide-gray-200 shadow-md rounded-lg overflow-hidden">
    <thead class="bg-green-900">
      <tr>
        <th class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
          Investment Amount
        </th>
        <th class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
          Amount After vestment Period
        </th>
      </tr>
    </thead>
    <tbody class="bg-green-900 divide-y divide-gray-200">
      <tr>
        <td class="px-6 py-4 whitespace-nowrap">5,000</td>
        <td class="px-6 py-4 whitespace-nowrap">8,000</td>
      </tr>
      <tr>
        <td class="px-6 py-4 whitespace-nowrap">10,000</td>
        <td class="px-6 py-4 whitespace-nowrap">15,000</td>
      </tr>
      <tr>
        <td class="px-6 py-4 whitespace-nowrap">20,000</td>
        <td class="px-6 py-4 whitespace-nowrap">35,0000</td>
      </tr>
      <tr>
        <td class="px-6 py-4 whitespace-nowrap">50,000</td>
        <td class="px-6 py-4 whitespace-nowrap">85,000</td>
      </tr>
      <tr>
        <td class="px-6 py-4 whitespace-nowrap">70,000</td>
        <td class="px-6 py-4 whitespace-nowrap">120,000</td>
      </tr>
      <tr>
        <td class="px-6 py-4 whitespace-nowrap">90,000</td>
        <td class="px-6 py-4 whitespace-nowrap">140,000</td>
      </tr>
    </tbody>
  </table>
</div>

          <button className="my-6 text-green-500 hover:text-green-900 hover:text-white rounded-xl p-4 w-[200px] mx-auto">
            <Link to="/register">Invest Now</Link>
          </button>
        </div>

        <div className="shadow-xl flex flex-col p-2 my-4 rounded-lg text-green-100">
          <h1 className="font-bold text-3xl sm:text-4xl md:text-5xl mx-8 text-green-900">
            Long-term Plan
          </h1>

          <h1 className="text-3xl text-black sm:text-4xl md:text-5xl font-bold mx-8 my-3">
            <span>₦</span>200,000
          </h1>

          <p className="text-[rgb(111,111,111)] mx-8 my-5">Minimum Deposit</p>

          <h1 className="text-green-900 font-bold text-2xl sm:text-3xl md:text-4xl mx-8 my-3 underline">
            Features
          </h1>

          <p className="font-bold text-[rgb(111,111,111)] p-4">
            Two hundrethousand  Naira (₦200,000.00) and above. The invested amount from
            Two hundrethousand  Naira (₦200,000.00) will be receive a (22%) Return on
            investments after the investment time frame ends Earnings will be
            deposited to the client's provided payment account in ( 1 ) month.
          </p>
          <div class="flex justify-center">
  <table class="min-w-full divide-y divide-gray-200 shadow-md rounded-lg overflow-hidden">
    <thead class="bg-green-900">
      <tr>
        <th class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
          Investment Amount
        </th>
        <th class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
          Amount After vestment Period
        </th>
      </tr>
    </thead>
    <tbody class="bg-green-900 divide-y divide-gray-200">
      <tr>
        <td class="px-6 py-4 whitespace-nowrap">200,000</td>
        <td class="px-6 py-4 whitespace-nowrap">300,000</td>
      </tr>
      <tr>
        <td class="px-6 py-4 whitespace-nowrap">300,000</td>
        <td class="px-6 py-4 whitespace-nowrap">500,000</td>
      </tr>
      <tr>
        <td class="px-6 py-4 whitespace-nowrap">500,000</td>
        <td class="px-6 py-4 whitespace-nowrap">800,000</td>
      </tr>
     
    </tbody>
  </table>
</div>

          <button className="my-6 text-green-500 hover:text-green-900 hover:text-white rounded-xl p-4 w-[200px] mx-auto">
            <Link to="/register">Invest Now</Link>
          </button>
        </div>

        {/* <div  className='shadow-xl flex flex-col p-2 my-4 rounded-lg text-green-100'>
        <h1 className='font-bold text-3xl sm:text-4xl md:text-5xl mx-8 text-green-900'>Professional</h1>

        <h1 className='text-3xl sm:text-4xl md:text-5xl font-bold mx-8 my-3'><span>₦</span>2500</h1>

        <p className='text-[rgb(111,111,111)] mx-8 my-5'>Minimum Deposit</p>
        
        <h1 className='text-green-900 font-bold text-2xl sm:text-3xl md:text-4xl mx-8 my-3 underline'>Features</h1>

        <ul>

          <li><p className='font-bold text-[rgb(111,111,111)] my-5 mx-8'>&#10004;Starting oppurtunity for online trading of various financial instruments.</p></li>
          <li><p className='font-bold text-[rgb(111,111,111)] my-5 mx-8'>&#10004;Leverage 1:20</p></li>
          <li><p className='font-bold text-[rgb(111,111,111)] my-5 mx-8'>&#10004;Deposit Insurance</p></li>
          <li><p className='font-bold text-[rgb(111,111,111)] my-5 mx-8'>&#10004;Instant Execution</p></li>
          <li><p className='font-bold text-[rgb(111,111,111)] my-5 mx-8'>&#10004;Deposit Protection</p></li>
          <li><p className='font-bold text-[rgb(111,111,111)] my-5 mx-8'>&#10006;24/7 Support</p></li>
        </ul>

        <button className='my-6 text-green-500 hover:text-green-900 hover:text-white rounded-xl p-4 w-[200px] mx-auto'><Link to='/register'>Invest Now</Link></button>
        </div>

        
      

      
        <div  className='shadow-xl flex flex-col p-2 my-4 rounded-lg text-green-100'>
        <h1 className='font-bold text-3xl sm:text-4xl md:text-5xl mx-8 text-green-900'>Premium</h1>

        <h1 className='text-3xl sm:text-4xl md:text-5xl font-bold mx-8 my-3'><span>₦</span>5000</h1>

        <p className='text-[rgb(111,111,111)] mx-8 my-5'>Minimum Deposit</p>
        
        <h1 className='text-green-900 font-bold text-2xl sm:text-3xl md:text-4xl mx-8 my-3 underline'>Features</h1>

        <ul>

          <li><p className='font-bold text-[rgb(111,111,111)] my-5 mx-8'>&#10004;Starting oppurtunity for online trading of various financial instruments.</p></li>
          <li><p className='font-bold text-[rgb(111,111,111)] my-5 mx-8'>&#10004;Leverage 1:20</p></li>
          <li><p className='font-bold text-[rgb(111,111,111)] my-5 mx-8'>&#10004;Deposit Insurance</p></li>
          <li><p className='font-bold text-[rgb(111,111,111)] my-5 mx-8'>&#10004;Instant Execution</p></li>
          <li><p className='font-bold text-[rgb(111,111,111)] my-5 mx-8'>&#10004;Deposit Protection</p></li>
          <li><p className='font-bold text-[rgb(111,111,111)] my-5 mx-8'>&#10004;24/7 Support</p></li>
        </ul>

        <button className='my-6 text-green-500 hover:text-green-900 hover:text-white rounded-xl p-4 w-[200px] mx-auto'><Link to='/register'>Invest Now</Link></button>
        </div> */}
      </div>
    </div>
  );
}

export default FeatureCards;

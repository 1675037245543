const faqinfo = [
  {
    id: 1,
    question: "What is AGROVEST Invest ?",
    answer:
      "AGROVEST is a unique investment platform that allows increasing the capital of online investors and owners of crypto assets from all corners of the world.",
  },

  {
    id: 2,
    question: "How Can I Register A New Account ?",
    answer:
      'To register a new account, simply click the "Register New Account" button or "Sign Up" link and fill out the required information.',
  },

  {
    id: 3,
    question: "Who Manages The Investment Portfolio ?",
    answer:
      "Investment portfolio management is cardrried out by a team of experienced employees of AGROVEST Invest. Thoroughly weighing each of its acttions, the company guarantees the safety of all investors funds and payments in full in a timely manner.",
  },

  {
    id: 4,
    question: "How Can I Add Money To My Account And Create A Deposit ?",
    answer:
      'Login to your account, go to "Create a deposit", select the payment system (Bitcoin, Perfect Money and Ethereum is available). Choose an investment plan, indicate the desired amount of investment, click on "Pay".',
  },

  {
    id: 5,
    question: "Can I Have Several Accounts In The System ?",
    answer:
      "AGROVEST Invest allows investors to possess more than one account to suit the capital assigned to a certain investment plan. Another account can be created using the same Email account but different username and password.",
  },

  {
    id: 6,
    question: "What Payment Systems Do You Work With ?",
    answer:
      "At the moment, we use Bitcoin, Dollars, Pounds and Ethereum. The company also plans to expand the list of payment methods.",
  },

  {
    id: 7,
    question: "What Actions Can Be Perfomed on My Account ?",
    answer:
      "You can change your personal information and password, make investments, withdraw funds, view the history of deposits and operations with balance and get a unique referral link to participate in the referral program.",
  },

  {
    id: 8,
    question: "How Can I Change Personal information In My Account ?",
    answer:
      'Choose "Account settings" in the menu in your account. Enter the new data and then click "change account settings" to save the changes.',
  },

  {
    id: 9,
    question:
      "What Can I Do If I Can't Login To My Account Because I Have Forgotten My Password ?",
    answer:
      'Click on "Forgot Password?" and then follow the instructions to restore access to your account.',
  },

  {
    id: 10,
    question: "I Can't Log Into My Account, What Can Cause The Problem ?",
    answer:
      "If you can't log into your personal account on our website then you probably entered incorrect login or password.",
  },

  {
    id: 11,
    question: "What Is The Minimum/Maximum I Can Invest ?",
    answer:
      "You can invest in our platform any amount, starting from 0.018BTC or 0.27ETH.The maximum amount of deposit is 0.1775BTC or 2.70ETH. For each of the plans, the same amount limits.",
  },

  {
    id: 12,
    question: "What Is The Minimum/Maximum I Can Withdraw ?",
    answer:
      "The amount of earnings is calculated on the basis of a particular investment plan and the amount of the deposit. The maximum earnings are not limited. Remember, the more your investment, the higher your income.",
  },

  {
    id: 13,
    question:
      "How Soon Will My Deposit Be Added To My Account After The Transfer Of Funds ?",
    answer:
      "A deposit will be created automatically, immediately after three confirmations in the Blockchain network for BTC and 5 confirmations for Ethereum.",
  },

  {
    id: 14,
    question: "Can I Create Several Deposits At Once ?",
    answer: "Yes. One user can create an unlimited number of deposits.",
  },

  {
    id: 15,
    question: "How Secure Is The Site And My Account Information ?",
    answer:
      "The company guarantees completes confidentiality of each user's data. Your personal information is 100% protected from attacks by third parties.",
  },
];

export default faqinfo;

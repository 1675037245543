import React from "react";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import Work from "../Assets/Work.jpg";
import Vision from "../Assets/Vision.jpg";
import Verification from "../Assets/Verification.jpg";

function TradingConditions() {
  return (
    <div>
      <Navbar />
      <div className="w-full pb-10 text-white">
        <div className="px-4 mt-[66px] max-w-[1330px] mx-auto pb-10 bg-[#024415D8] rounded-[50px]">
          <div className="flex justify-center">
            <h1 className="mt-8 mx-auto text-center text-4xl md:text-7xl font-bold">
              AGROVEST Trading Conditions
            </h1>
          </div>
        </div>
      </div>

      <div className="py-16 w-full px-4">
        <div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
          <div className="flex flex-col justify-center">
            <h1 className="text-green-900 font-bold md:text-5xl sm:text-4xl text-3xl pb-8">
              Favourable Trading Conditions
            </h1>
            <h2 className="text-green-900 font-bold text-[15px]">
              SECURITY REPORTS
            </h2>
            <p className="p-3 text-[rgb(111,111,111)] font-bold">
              AGROVEST Invest strives to be the most trustworthy and secure
              marketplace for investors. Finding and eliminating current
              vulnerabilities is a top priority. At AGROVEST we value our
              partnership with our investor community and as such, we ensure all
              reports are reviewed by security experts and acted upon
              appropriately.
            </p>

            <h2 className="text-green-900 font-bold text-[15px]">
              CO-LOCATION AND CROSS CONNECTIVITY
            </h2>
            <p className="p-3 text-[rgb(111,111,111)] font-bold">
              Partnering with Market Synergy, corporate accounts and
              professional traders can take advantage of the fastest trading
              speeds through institutional grade connectivity and co-location
              services with direct access to our digital asset gateway.
            </p>
          </div>
          <img
            className="w-[400px] mx-auto my-4 rounded-[20px]"
            src={Work}
            alt="img"
          />
        </div>

        <div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
          <div className="flex flex-col justify-center">
            <h2 className="text-green-900 font-bold text-[15px]">
              SUB-ACCOUNTS
            </h2>
            <p className="p-3 text-[rgb(111,111,111)] font-bold">
              Corporate accounts and professional traders are able to create
              sub-accounts. Some of the many benefits include grouping together
              of trade volume for fee tiers, and seperate deposit and withdrawal
              permissions.
            </p>

            <h2 className="text-green-900 font-bold text-[15px]">
              ERRORS AND REFUNDS
            </h2>
            <p className="p-3 text-[rgb(111,111,111)] font-bold">
              AGROVEST may provide refunds in certain circumstances as it's sole
              discretion. If you believe an error has occured or you would like
              to cancel your transactions, please contact our Help Center. Our
              Refund and Error Resolution team will be on standby.
            </p>
          </div>
          <img
            className="w-[600px] mx-auto my-4 rounded-[20px]"
            src={Vision}
            alt="img"
          />
        </div>
      </div>

      <div className="w-full text-white pb-10">
        <div className="px-4 mt-[66px] max-w-[1330px] mx-auto pb-10 bg-[#024415D8] rounded-[50px]">
          <div className="flex flex-col justify-center">
            <h1 className="mt-8 mx-auto text-center text-4xl md:text-7xl font-bold">
              How To Verify Your Identity
            </h1>
            <p className="mt-8 mx-5 text-center">
              To prevent fraud and to make any account-related changes, AGROVEST
              will ask you to Verify your identity for the 1st, 2nd and 3rd
              tiers. We also ask you to verify your identity to ensure no one
              but you can change your payment information.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full text-[rgb(111,111,111)] py-[6rem] px-4">
        <div className="max-w-[1240px] mx-auto grid md:grid-cols-2 gap-10">
          <img
            className="w-[500px] mx-auto my-4 rounded-[20px]"
            src={Verification}
            alt="img"
          />
          <div className="flex flex-col justify-center">
            <h1 className="text-2xl font-bold">TIER ONE VERIFICATION</h1>
            <p className="p-3">
              Sign up and make first digital action with your portfolio acoount.
            </p>
            <h1 className="text-2xl font-bold">ACCOUNT VERIFICATION</h1>
            <p className="p-3">
              As a regulated financial service company. AGROVEST Invest is
              required to identify users on our platform. AGROVEST Invest can
              only accept the following documents: Accepted identity documents
              for 2 tier verification United States. State-issued IDs such as
              Driver's License or identification card outside of the United
              States, Government-issued photo ID.
            </p>

            <ul className="list-disc mx-9">
              <li>
                <p>National Identity Card</p>
              </li>
              <li>
                <p>Passport</p>
              </li>
            </ul>

            <h1 className="text-2xl font-bold">
              UNACCEPTED IDENTITY DOCUMENTS
            </h1>
            <ul className="list-disc mx-9 p-3">
              <li>
                <p>United States Passports</p>
              </li>
              <li>
                <p>United States Permanent Resident Card(Green Card)</p>
              </li>
              <li>
                <p>School IDs</p>
              </li>
              <li>
                <p>Medical IDs</p>
              </li>
              <li>
                <p>Temporary (paper) IDs</p>
              </li>
              <li>
                <p>Resident Permit</p>
              </li>
              <li>
                <p>Public Service Card</p>
              </li>
            </ul>

            <h1 className="text-2xl fot-bold p-3 font-bold">Please Note:</h1>
            <ul className="list-disc mx-9">
              <li>
                <p>
                  As part of our commitment to remain the most trusted
                  cryptocurrency platform, all ID documents must be verified
                  through the AGROVEST Invest website or mobile app. We don't
                  accept emailed copies of our ID documents for verification
                  purposes.
                </p>
              </li>

              <li>
                <p className="my-2">
                  Changing your legal name or country of residence requires you
                  to update your ID document. If you're changing your country of
                  residence, you'll need to upload a valid ID from the country
                  you're currently residing in. You can change your personal
                  information from your Profile Page.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="w-full bg-gray-200 px-4">
        <div className="max-w-[1240px] mx-auto">
          <div className="flex flex-col justify center">
            <h1 className="text-green-900 font-bold md:text-6xl sm:text-4xl text-3xl">
              AGROVEST Community Rules
            </h1>
            <ol className="list-decimal p-3">
              <li className="my-4">
                <p>Never share your password or account information.</p>
              </li>
              <li className="my-4">
                <p>
                  Beware of impersonators! Make sure to see their trust level.
                </p>
              </li>
              <li className="my-4">
                <p>Beware of sketchy links.</p>
              </li>
              <li className="my-4">
                <p>
                  Never trust someone asking you to "authenticate your wallet"
                </p>
              </li>
              <li className="my-4">
                <p>
                  Never import a private key or seed phrase to your wallet that
                  someone gave you.
                </p>
              </li>
              <li className="my-4">
                <p>
                  AGROVEST Support will never message you directly to help you
                </p>
              </li>
              <li className="my-4">
                <p>
                  Whatsapp groups, Wechatgroups, spam discord channels, or
                  Twitter direct messages. These are all scams. AGROVEST does
                  not provide support via these platforms.
                </p>
              </li>
              <li className="my-4">
                <p>Report scammers. You can help the stay community safe!</p>
              </li>
              <li className="my-4">
                <p>Beware of fake websites.</p>
              </li>
              <li className="my-4">
                <p>Send emails to support@AGROVESTinvest00@gmail.com</p>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default TradingConditions;

import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import Single from "../Assets/single.png";
import Avatar from "react-avatar-edit";
// import cloudinary from '../Components/cloudinary';

const Profile = () => {
  const [image, setimage] = useState(false);
  const [imagecrop, setimagecrop] = useState("");
  const [src, setsrc] = useState(false);
  const [profile, setprofile] = useState([]);
  const [pview, setpview] = useState(false);

  const profilefinal = profile.map((item) => item.pview);

  const onClose = () => {
    setpview(null);
  };

  const onCrop = (view) => {
    setpview(view);
  };

  const saveCropImage = () => {
    setprofile([...profile, { pview }]);
  };

  // const[image, setImage] = useState(null)

  // const handleProfile = async (e) => {
  //   const file = e.target.files[0];
  //   const formData = new FormData();
  //   formData.append('file', file);
  //   formData.append('upload_preset', 'rcifsao9');

  //   const response = await cloudinary.uploader.upload(formData);
  //   console.log(response.secure_url)
  // };
  return (
    <div>
      <div className="profile_img text-center p-4">
        <div className="flex flex-col justify-center items-center">
          <img
            style={{
              width: "200px",
              height: "200px",
              borderRadius: "50%",
              objectFit: "cover",
              border: "4px solid blue",
            }}
            onClick={() => setimagecrop(true)}
            src={profilefinal.length ? profilefinal : Single}
            alt="/img"
          />

          <Dialog
            visible={imagecrop}
            header={() => (
              <p className="text-2xl font-semibold text-center">
                Update Profile
              </p>
            )}
            onHide={() => setimagecrop(false)}
          >
            <div className="confirmation-content flex flex-col items-center">
              <Avatar
                width={500}
                height={400}
                onCrop={onCrop}
                onClose={onClose}
                src={src}
                shadingColor={"#474649"}
                backgroundColor={"#474649"}
              />

              <div className="flex flex-col items-center mt-5 w-12">
                <div className="flex justify-around w-12 mt-4">
                  <Button
                    onClick={saveCropImage}
                    label="Save"
                    icon="pi pi check"
                    className="text-green-600 p-4 rounded-lg text-white"
                  />
                </div>
              </div>
            </div>
          </Dialog>

          <InputText
            type="file"
            accept="/image/*"
            style={{ display: "none" }}
            onChange={(event) => {
              const file = event.target.files[0];
              if (file && file.type.substring(0, 5) === "image") {
                setimage(file);
              } else {
                setimage(null);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Profile;

import React from "react";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import Stock from "../Assets/Stocks.jpg";
import Office from "../Assets/Office.jpg";
import { Link } from "react-router-dom";
import Features from "../Components/Features";

function Stocks() {
  return (
    <div>
      <Navbar />
      <div className="w-full text-white pb-10">
        <div className="px-4 mt-[66px] max-w-[1330px] mx-auto pb-10 bg-[#024415D8] rounded-[50px]">
          <div className="flex justify-center">
            <h1 className="mt-8 mx-auto text-center text-4xl md:text-7xl font-bold">
              Stock Value on AGROVEST
            </h1>
          </div>
        </div>

        <div className="py-20 px-4 h-[90vh] md:h-[30vh] lg:h-[70vh]">
          <div className="max-w-[1240px] mx-auto grid md:grid-cols-2 gap-8">
            <img
              className="w-[500px] mx-auto my-4 rounded-[20px]"
              src={Stock}
              alt="img"
            />
            <div className="flex flex-col justify-center">
              <h1 className="text-green-900 font-bold md:text-6xl md:mx-3 sm:text-4xl text-3xl">
                CFD Trades on Stocks
              </h1>

              <p className="p-3 text-[rgb(111,111,111)] font-bold">
                To earn money on trading stocks of companies, it is not
                necessary to buy them. CFDs are very easy to execute, as it is
                possible to trade stocks in any direction.
              </p>

              <h4 className="underline font-bold text-green-900 p-3">
                <Link to="/register" className="link">
                  Open Account
                </Link>
              </h4>
            </div>
          </div>
        </div>

        <div className="w-full px-4 py-[4rem] md:h-[30vh] lg:h-[60vh] h-[110vh]">
          <div className="max-w-[1240px] mx-auto grid sm:grid-cols-2 md:grid-cols-3 gap-8">
            <div className="shadow-xl flex flex-col p-2 my-4 rouded-lg text-green-100">
              <h1 className="font-bold text-green-900 text-2xl">
                Short And Long Positions
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                It is possible to trade on the stock market in any direction by
                opening BUY and SELL orders.
              </p>
            </div>

            <div className="shadow-xl flex flex-col p-2 my-4 rouded-lg text-green-100">
              <h1 className="font-bold text-green-900 text-2xl">
                Modern Web CFD Trading Platform
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                The multifunctional trading terminal simplifies the analysis and
                online trading of stocks of companies.
              </p>
            </div>

            <div className="shadow-xl flex flex-col p-2 my-4 rouded-lg text-green-100">
              <h1 className="font-bold text-green-900 text-2xl">
                Without A Physical Purchase
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                ICFD trading in stocks does not involve the bureaucracy of
                physically buying securities and promissory notes.
              </p>
            </div>
          </div>
        </div>
        <Features />

        <div className="py-16 w-full px-4 h-[110vh] md:h-[30vh] lg:h-[80vh]">
          <div className="max-w-[1240px] mx-auto grid md:grid-cols-2 gap-8">
            <div className="flex flex-col justify-center">
              <h1 className="text-green-900 font-bold md:text-6xl sm:text-4xl text-3xl">
                More About Stock Trading
              </h1>
              <p className="p-3 text-[rgb(111,111,111)] font-bold">
                Large companies go through the IPO process issuing their stocks
                on global exchanges. The value of a company's securities depends
                on its financial performance, fundamental factors, and the
                behaviour of market participants. Study the activities of the
                most promising corporations to trade short and long positions.
              </p>
              <h4 className="underline font-bold text-green-900 p-3">
                <Link to="/register" className="link">
                  Open Account
                </Link>
              </h4>
            </div>
            <img
              className="w-[600px] mx-auto my-4 rounded-[20px]"
              src={Office}
              alt="img"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Stocks;

import React from "react";

function Contract() {
  return (
    <div className="w-full py-[4rem] px-4 bg-gray-200">
      <div className="max-w-[1240px] mx-auto">
        <h1 className="text-green-900 font-bold md:text-6xl sm:text-4xl text-3xl text-center">
          Briefing and Clarifications
        </h1>
        <p className="font-bold my-4 p-4 ">
        If a client invests ₦200,000.00, they would receive a ₦100,000.00 profit, which is equivalent to One Hundred Thousand Naira. After one month, the total return on the investment would be ₦300,000.00, comprising the initial investment of ₦200,000.00 and the ₦100,000.00 profit. This represents a 50% return on every ₦200,000.00 invested. To proceed, you will need to sign an agreement indicating the invested amount.
        </p>

        <h1 className="text-green-900 font-bold md:text-6xl sm:text-4xl text-3xl text-center">
          Contract terms and conditions.
        </h1>
        <p className="font-bold my-4 p-4">
          The contract starts on the date the deposit is made, and ends in one
          month, as for Short term investment it ends in 46 hours. This contract
          is only valid for one month. And after receiving payment of
          investment and profit the client can renew the contract if interested.
          Clients are granted a 5% Referral Commission on each of your
          referral's first Deposit.
        </p>

        <h1 className="text-green-900 font-bold md:text-6xl sm:text-4xl text-3xl text-center">
          Payment terms and conditions
        </h1>
        <p className="font-bold my-4 p-4">
          Cash transactions are not required. All payments will be sent to a
          provided client bank or business account. The client shall have a
          personal or business account to receive the payment. Banks we partner
          with are as follows: BOA, CHASE BANK, CHIME BANK, ETC. A client can
          also have His/Her returns sent to a provided Bitcoin wallet. If a
          client uses any of the mentioned banks, they can get their investment
          profits sent straight to their accounts. All investors are responsible
          to pay SARS or tax on their profit returns as the law applies. The
          company AGROVEST LTD is not accountable for non-investors tax
          compliance with the law of any country.
        </p>
      </div>
    </div>
  );
}

export default Contract;

import React from "react";
import Benefit from "../Assets/Benefits.jpg";
import { Link } from "react-router-dom";

function Benefits() {
  return (
    <div className="w-full py-16 px-4 text-green-900">
      <div
        className="max-w-[1240px] mx-auto grid md:grid-cols-2 gap-8"
        id="benefits"
      >
        <img
          className="w-[500px] mx-auto my-4 rounded-[20px]"
          src={Benefit}
          alt="img"
        />
        <div className="flex flex-col justify-center">
          <h1 className="text-green font-bold md:text-6xl sm:text-4xl text-3xl md:mx-3">
            Benefits From Numerous Markets
          </h1>
          <p className="font-bold text-white p-2">
            There are several exchange markets where liquid financial
            instruments are traded.
          </p>
          <h2 className="font-bold text-green underline p-3">
            <Link to="/market">Learn More</Link>
          </h2>
        </div>
      </div>
    </div>
  );
}

export default Benefits;

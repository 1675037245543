import React from "react";
import {
  FaFacebookSquare,
  FaGithubSquare,
  FaInstagram,
  FaLinkedin,
  FaTwitterSquare,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { NavHashLink as NavLink } from "react-router-hash-link";

function Footer() {
  return (
    <div className="w-full text-green-900 mx-auto grid lg:grid-cols-3 gap-8 py-[6rem] px-4">
      <div>
        <h1 className="w-full text-3xl font-bold">
          <Link to="/">AGROVEST INVEST</Link>
        </h1>
        <p className="py-4">
          AGROVEST Invest is the number one user friendly platform and agency to
          help traders and investors all over the world to have control of and
          insight on equity.
        </p>
        <div className="flex gap-6 md:w-[75%] my-6">
          <FaFacebookSquare size={30} />
          {/* <FaLinkedin size={30}/> */}
          <FaInstagram size={30} />
          {/* <FaGithubSquare size={30}/>
            <FaTwitterSquare size={30}/> */}
        </div>
      </div>

      <div className="lg:col-span-2 flex justify-between mt-6">
        <div className="mr-2">
          <h1 className="font-medium md:text-3xl text-white">
            <Link to="/about">About</Link>
          </h1>
          <h1 className="font-medium md:text-3xl text-white">
            <Link to="/contact">Contact</Link>
          </h1>
          <h1 className="font-medium md:text-3xl text-white">
            <NavLink to="/#features">Features</NavLink>
          </h1>
        </div>

        <div>
          <h6 className="font-medium text-[rgb(111,111,111)]">
            <Link to="/tradingconditions">Trading Conditions</Link>
          </h6>
          <ul>
            <li className="py-2 text-sm">
              <Link to="/faq">FAQ</Link>
            </li>
            <li className="py-2 text-sm">
              <Link to="/license">Licensing and Accreditations</Link>
            </li>
            <li className="py-2 text-sm">
              <Link to="/commodities">Commodities</Link>
            </li>
            <li className="py-2 text-sm">
              <Link to="/cryptomining">Cryptomining</Link>
            </li>
          </ul>
        </div>

        <div>
          <h6 className="font-medium text-[rgb(111,111,111)]">
            <Link to="/market">Market</Link>
          </h6>
          <ul>
            <li className="py-2 text-sm">
              <Link to="/cryptocurrencies">Crypto-Currency</Link>
            </li>
            <li className="py-2 text-sm">
              <Link to="/indices">Indices</Link>
            </li>
            <li className="py-2 text-sm">
              <Link to="/metals">Metals</Link>
            </li>
            <li className="py-2 text-sm">
              <Link to="/stocks">Stocks</Link>
            </li>
          </ul>
        </div>

        <div>
          <h6 className="font-medium text-[rgb(111,111,111)]">
            
          </h6>
          <ul>
            <li className="py-2 text-sm">
              <NavLink to="/#benefits">Benefits</NavLink>
            </li>
            <li className="py-2 text-sm">
              <NavLink to="/#tradingsteps">Trading-steps</NavLink>
            </li>
            <li className="py-2 text-sm">
              <NavLink to="/#referral">Referral</NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Footer;

const faqdata = [
  {
    id: 1,
    question: "What is AGROVEST Invest ?",
    answer:
      "AGROVEST Invest is a unique investment platform that allows the increase in capital of online investors and owners of crypto assets from all corners of the world.",
  },

  {
    id: 2,
    question: "How Can I Register A New Account ?",
    answer:
      'To register a new account, simply click the "Register New Account" button or "Sign Up" link and fill out the required information.',
  },

  {
    id: 3,
    question: "Who Manages The Investment Portfolio ?",
    answer:
      "Investment portfolio management is carried out by a team of experienced employees of AGROVEST Invest. Thoroughly weighing each of its acttions, the company guarantees the safety of all investors' funds and payments in full in a timely manner.",
  },

  {
    id: 4,
    question: "How Can I Add Money To My Account And Create A Deposit ?",
    answer:
      "Login to your account, go to 'Create a deposit', select the payment system (Bitcoin, Dollars, Pounds and Ethereum is available). Choose an investment plan, indicate the desired amount of investment, click on `Pay`.",
  },

  {
    id: 5,
    question: "Can I Have Several Accounts In The System ?",
    answer:
      "AGROVEST Invest is a unique investment platform that allows increasing the capital of online investors and owners of crypto assets from all corners of the world.",
  },

  {
    id: 6,
    question: "What Payment Systems Do You Work With ?",
    answer:
      "At the moment, we use Bitcoin, Perfect Money and Ethereum. The company also plans to expand the list of payment methods. You can learn about adding new payment systems from our news.",
  },
];

export default faqdata;

import React from "react";
import { FaMoneyCheckAlt, FaChartLine, FaChartPie } from "react-icons/fa";

function InvestmentFactors() {
  return (
    <div className="max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-3 gap-10">
      <div>
        <h1 className="w-full text-4xl font-bold text-green-900">
          Factors To Consider When Selecting An Investment Platform
        </h1>
        <p className="py-4 font-bold text-[rgb(111,111,111)]">
          The software for online trading is characterized by speed,
          functionality, and unique yet simple web design.
        </p>
      </div>
      <div
        className="lg:col-span-2 sm:flex sm:flex-row md:flex gap-8 justify-between mt-6"
        id="factors"
      >
        <div>
          <FaMoneyCheckAlt className="text-green-900 text-3xl mb-3" />
          <h1 className="font-bold text-2xl text-green-900 ">
            Quick Deposit And Withdrawal
          </h1>
          <p className="p-2  text-[rgb(111,111,111)] font-bold">
            Request for deposit and withdrawals are processed automatically
            within ample time.
          </p>
        </div>
        <div>
          <FaChartLine className="text-green-900 text-3xl mb-3" />
          <h1 className="font-bold text-2xl text-green-900 ">
            Credit Leverage & The variable Of Volume Deals
          </h1>
          <p className="p-2  font-bold text-[rgb(111,111,111)]">
            Advanced order settings is a function of just a few clicks, as they
            are important for subtle options.
          </p>
        </div>
        <div>
          <FaChartPie className="text-green-900 text-3xl mb-3" />
          <h1 className="font-bold text-2xl text-green-900 ">
            Regular Adding Of New Instruments
          </h1>
          <p className="p-2  font-bold text-[rgb(111,111,111)]">
            New assests are constantly being added to the existing categories of
            financial instruments and lots more.
          </p>
        </div>
      </div>
    </div>
  );
}

export default InvestmentFactors;

import React from "react";
import { Link } from "react-router-dom";
import Commodity from "../Assets/Commodities.jpg";
import CryptoCurrency from "../Assets/Crypto.jpg";
import Mining from "../Assets/Mining.jpg";
import Indicies from "../Assets/Indices.jpg";
import Metals from "../Assets/Metals.jpg";
import Stocks from "../Assets/Stocks.jpg";

function MarketCards() {
  return (
    <div className="w-full py-[6rem] px-4 ">
      <div className="max-w-[1240px] mx-auto grid sm:grid-cols-2 md:grid-cols-3 gap-8">
        <div className="shadow-xl flex flex-col p-2 my-4 rounded-lg text-green-100">
          <img className="rounded-lg bg-white" src={Commodity} alt="img" />
          <h1 className="font-bold text-green-900 text-2xl">Commodity</h1>
          <p className="p-2 text-[rgb(111,111,111)]">
            The commodity market is mainly represented by energy resources: oil
            and gas.
          </p>
          <h2 className="font-bold text-green-900 underline p-3">
            <Link to="/commodities">Learn More</Link>
          </h2>
        </div>

        <div className="shadow-xl flex flex-col p-2 my-4 rounded-lg text-green-100">
          <img className="rounded-lg bg-white" src={CryptoCurrency} alt="img" />
          <h1 className="font-bold text-green-900 text-2xl">CryptoCurrencies</h1>
          <p className="p-2 text-[rgb(111,111,111)]">
            Decentralized digital money has long been used by investors and
            traders.
          </p>
          <h2 className="font-bold text-green-900 underline p-3">
            <Link to="/cryptocurrencies">Learn More</Link>
          </h2>
        </div>

        <div className="shadow-xl flex flex-col p-2 my-4 rounded-lg text-green-100">
          <img className="rounded-lg bg-white" src={Mining} alt="img" />
          <h1 className="font-bold text-green-900 text-2xl">Crypto Mining</h1>
          <p className="p-2 text-[rgb(111,111,111)]">
            Crypto Mining is a process in which Bitcoin and several other
            cryptocurrencies are used to generate new coins and verify new
            transactions.
          </p>
          <h2 className="font-bold text-green-900 underline p-3">
            <Link to="/cryptomining">Learn More</Link>
          </h2>
        </div>

        <div className="shadow-xl flex flex-col p-2 my-4 rounded-lg text-green-100">
          <img className="rounded-lg bg-white" src={Indicies} alt="img" />
          <h1 className="font-bold text-green-900 text-2xl">Indices</h1>
          <p className="p-2 text-[rgb(111,111,111)]">
            Stock indices are quotes of the average price of stocks of companies
            in one group(for example,industry or IT giants).
          </p>
          <h2 className="font-bold text-green-900 underline p-3">
            <Link to="/indices">Learn More</Link>
          </h2>
        </div>

        <div className="shadow-xl flex flex-col p-2 my-4 rounded-lg text-green-100">
          <img className="rounded-lg bg-white" src={Metals} alt="img" />
          <h1 className="font-bold text-green-900 text-2xl">Metals</h1>
          <p className="p-2 text-[rgb(111,111,111)]">
            Gold,silver, and platinum are precious metals that are traded at the
            world's largest commodity exchanges.
          </p>
          <h2 className="font-bold text-green-900 underline p-3">
            <Link to="/metals">Learn More</Link>
          </h2>
        </div>

        <div className="shadow-xl flex flex-col p-2 my-4 rounded-lg text-green-100">
          <img className="rounded-lg bg-white" src={Stocks} alt="img" />
          <h1 className="font-bold text-green-900 text-2xl">Stocks</h1>
          <p className="p-2 text-[rgb(111,111,111)]">
            The stocks of companies are interesting to investors and private
            traders.
          </p>
          <h2 className="font-bold text-green-900 underline p-3">
            <Link to="/stocks">Learn More</Link>
          </h2>
        </div>
      </div>
    </div>
  );
}

export default MarketCards;
